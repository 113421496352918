import React from "react"

import { withCorporateCancelled } from "./withCorporateCancelled"
import { Container, Button, Title, Emoji, Link } from "./Corporate.styled"

export const CorporateCancelled = withCorporateCancelled(
  ({ title, content }: Props): JSX.Element => (
    <Container width={`md`}>
      <Title>{title}</Title>
      <Emoji>😢</Emoji>
      {content}
      <Button as={Link} to={"/"} size={`large`} theme={`primary`} colour={`dark`}>
        Return Home
      </Button>
    </Container>
  )
)

export interface Props {
  title?: string
  content?: any
}
