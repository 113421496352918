import { GatsbyImage } from "gatsby-plugin-image"
import tw, { styled } from "twin.macro"

export { StyledButton } from "../../styled/Button"
export { StyledColumn as Column } from "../../styled/Column"
export { StyledContainer } from "../../styled/Container"
export { StyledResponsive as Responsive } from "../../styled/Responsive"
export { StyledLink as Link } from "../../styled/Link"

import { StyledButton } from "../../styled/Button"
import { StyledContainer } from "../../styled/Container"
import { H2, P } from "../../styled/Text"

export const Container = tw(StyledContainer)`h-full bg-grey-lightest md:bg-transparent md:h-auto flex flex-col items-center`
export const Button = tw(StyledButton)`block w-full md:w-auto mt-8 mb-8 text-center`
export const Title = tw(H2)`text-grey-dark mt-8 w-full md:mt-0 mb-8 text-center`
export const Emoji = tw.p`text-12xl text-center w-full leading-none`
export const Image = tw(GatsbyImage)`inline-block w-full absolute left-0 rounded-full w-12 h-12 border-4 border-white z-4`

export const Text = tw.div`md:text-xs mt-6`
