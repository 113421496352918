import React from "react"
import { graphql } from "gatsby"

import { CorporateCancelled as Page } from "../../components/Corporate/CorporateCancelled"

export const query = graphql`
  query {
    page: sanityPageCorporateCancelled {
      title
      content: _rawContent(resolveReferences: { maxDepth: 10 })
    }
  }
`

export default ({ data, ...props }) => <Page {...props} {...data} />
