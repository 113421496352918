import React, { useMemo } from "react"
import { navigate } from "gatsby"

import { useCore } from "../../hooks/useCore"
import { useApp } from "../../hooks/useApp"
import { useAnalytics } from "../../hooks/useAnalytics"

export const withCorporateCancelled = Component =>
  React.memo(({ name = "CorporateCancelled", page, location }) => {
    const {
      helpers: { sanityContent },
    } = useCore()
    const { ready } = useApp()
    const { trackCustomEvent } = useAnalytics() 

    const title = page?.title
    const content = sanityContent(page?.content)

    const params = new URLSearchParams(location?.search)
    const redirectUrl = params.get("redirectUrl") || false

    if (ready) {
      trackCustomEvent("corporateCancelled", {})
      if (redirectUrl) navigate(redirectUrl, { replace: true })
    }

    Component.displayName = name
    return useMemo(() => <Component title={title} content={content} />, [])
  })
